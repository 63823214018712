import { useCustomToasts } from '@systemeio/ui-shared'
import {
  getIsEmailStatusNotificationClosedCookie,
  setIsEmailStatusNotificationClosedCookie,
} from 'modules/funnels/funnel/utils/cookie-utils'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getEmailRestrictLink } from 'shared/components/dashboard-mail-editor/utils/get-email-restrict-link'
import TransLoco from 'shared/components/trans-loco'
import { MailingStatusEnum } from 'shared/enums/mailing-status-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { useSwrEmailStatusNotification } from 'shared/notifications/hooks/use-swr-email-status-notification'
import { getBlankLinkComponent } from 'shared/utils/get-blank-link-component'
import { getContactSupportLink } from 'shared/utils/get-contact-support-link'

export const useEmailStatusNotification = () => {
  const { pathname } = useRouter()
  const { user, isWorkspaceOwner } = useUser()
  const shouldRender =
    pathname.includes('/email-statistics') ||
    pathname.includes('/newsletters') ||
    pathname.includes('/campaigns') ||
    pathname.includes('/mailing')
  const isShowed = getIsEmailStatusNotificationClosedCookie()
  const { data: status } = useSwrEmailStatusNotification(shouldRender && !isShowed)
  const { infoToast } = useCustomToasts()

  const { t } = useLocoTranslation()

  useEffect(() => {
    if (!user || !isWorkspaceOwner || !status) return
    if (!shouldRender || !!isShowed) return
    switch (status.mailingStatus) {
      case MailingStatusEnum.pending: {
        infoToast(
          <TransLoco
            t={t}
            i18nKey={'dashboard.notifications.email_status.pending'}
            components={{
              a: getBlankLinkComponent(getEmailRestrictLink(user.dashboardLocale)),
            }}
          />,
          { className: 'lg:min-w-[600px]' },
          setIsEmailStatusNotificationClosedCookie,
        )
        break
      }
      case MailingStatusEnum.new_pending: {
        infoToast(
          <TransLoco
            t={t}
            i18nKey={'dashboard.notifications.email_status.new_user'}
            components={{
              a: getBlankLinkComponent(getEmailRestrictLink(user.dashboardLocale)),
            }}
          />,
          { className: 'lg:min-w-[600px]' },
          setIsEmailStatusNotificationClosedCookie,
        )
        break
      }
      case MailingStatusEnum.blocked: {
        infoToast(
          <TransLoco
            t={t}
            i18nKey={'dashboard.notifications.email_status.blocked'}
            components={{
              a: getBlankLinkComponent(getContactSupportLink(user.dashboardLocale)),
            }}
          />,
          { className: 'lg:min-w-[600px]' },
          setIsEmailStatusNotificationClosedCookie,
        )
        break
      }
      default: {
        return
      }
    }
    // TFunc should not be in dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkspaceOwner, user, status])
}
