import { ActionMenu, ActionMenuItem } from '@systemeio/ui-shared'
import React, { ReactNode, useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { getDataTestAttributes } from 'shared/utils/get-data-test-attributes'
import { twMerge } from 'tailwind-merge'
import { ActionMenuLinkItemNew } from '../action-menu-link-item-new'
import { ActionsInterface, ActionsRenderProps, CustomStateType } from './types'

export function ActionsRender<T, K>({
  actions,
  instance,
  caption,
  testAttributePostfix,
  actionsOrder,
  rowId,
}: ActionsRenderProps<T, K>) {
  const { t } = useLocoTranslation()
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isActivateOpen, setIsActivateOpen] = useState(false)
  const [isRemoveOpen, setIsRemoveOpen] = useState(false)

  const [isFetching, setIsFetching] = useState(false)

  const [isCustomOpen, setIsCustomOpen] = useState<CustomStateType<T>>(() => {
    const obj: CustomStateType<T> = {} as CustomStateType<T>
    actions.custom?.forEach(value => {
      obj[value.name] = false
    })
    return obj
  })

  const actionSideElements: JSX.Element[] = []

  const openLinkRender = () => {
    if (actions.openLink) {
      return actions.openLink.map(el => {
        const shouldRender = el.shouldRender ? el.shouldRender(instance) : true
        if (shouldRender) {
          return (
            <ActionMenuLinkItemNew
              isNext={el.isNext}
              openInNew={el.openInNew}
              dataTestAttributes={getDataTestAttributes(
                !!testAttributePostfix,
                `option-link-${rowId}`,
              )}
              key={`${el.name}-open-link`}
              href={el.setHref(instance)}
              label={el.caption ? el.caption : t('dashboard.actions.title.open')}
            />
          )
        }
      })
    }
  }

  const customRender = () => {
    if (actions.custom) {
      return actions.custom.map(el => {
        const shouldRender = el.shouldRender ? el.shouldRender(instance) : true
        const customActionRender = el.onActionRender
          ? el.onActionRender(
              isCustomOpen[el.name],
              () => setIsCustomOpen(prev => ({ ...prev, [el.name]: false })),
              instance,
              `${el.name}-modal`,
            )
          : undefined
        if (shouldRender) {
          customActionRender && actionSideElements.push(customActionRender)

          const Item = el.CustomActionMenuItem ?? ActionMenuItem

          return (
            //@ts-expect-error
            <Item
              key={`${el.name}-custom`}
              dataTestAttributes={getDataTestAttributes(
                !!testAttributePostfix,
                `option-${el.name}-${rowId}`,
              )}
              onClick={async () => {
                setIsCustomOpen(prev => ({ ...prev, [el.name]: true }))
                if (el.onActionClick) {
                  try {
                    setIsFetching(true)
                    await el.onActionClick(instance)
                    setIsFetching(false)
                  } catch (e) {
                    setIsFetching(false)
                  }
                }
              }}
              label={el.CustomActionMenuItem ? undefined : el.caption}
            />
          )
        }
      })
    }
  }

  const settingsRender = () => {
    if (actions.settings) {
      const settings = actions.settings
      const shouldRender = actions.settings.shouldRender
        ? actions.settings.shouldRender(instance)
        : true
      if (shouldRender) {
        actionSideElements.push(
          <EditModal
            key="settings-modal"
            opened={isSettingsOpen}
            onOpen={settings.onModalOpen}
            isPreFetching={settings.isFetching}
            onClose={() => {
              setIsSettingsOpen(false)
            }}
            fullCaption={settings.fullCaption}
            afterLeave={settings.onModalClose}
            caption={caption}
            editable={settings.editable}
            instance={instance}
            additionalData={settings.additionalData}
            onEdit={data => settings.onEdit(instance, data)}
            toastCaption={
              settings.toastCaption
                ? settings.toastCaption
                : t('dashboard.actions.updated', { module: caption })
            }
          />,
        )
        return (
          <ActionMenuItem
            key={'action-settings'}
            onClick={() => {
              setIsSettingsOpen(true)
            }}
            label={t('dashboard.actions.title.settings')}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-settings-${rowId}`,
            )}
          />
        )
      }
    }
  }

  const editRender = () => {
    if (actions.edit) {
      const shouldRender = actions.edit.shouldRender ? actions.edit.shouldRender(instance) : true
      const edit = actions.edit
      if (shouldRender) {
        return (
          <ActionMenuLinkItemNew
            key={'action-edit'}
            label={t('dashboard.actions.title.edit')}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-link-${rowId}`,
            )}
            href={edit.setHref(instance)}
            openInNew={edit.openInNew}
            isNext={edit.isNext}
          />
        )
      }
    }
  }

  const activateRender = () => {
    if (actions.activate) {
      const isActive = instance[(actions.activate.customStatusField || 'active') as keyof T]
      const activate = actions.activate
      const shouldRender = actions.activate.shouldRender
        ? actions.activate.shouldRender(instance)
        : true
      const confirmationText = actions.activate.confirmationText
        ? actions.activate.confirmationText
        : 'dashboard.actions.activate_confirmation'

      if (shouldRender) {
        actionSideElements.push(
          <Confirmation
            key="activate-modal"
            opened={isActivateOpen}
            onClose={() => setIsActivateOpen(false)}
            onConfirm={() => activate.onChange(instance)}
            confirmationText={t(confirmationText, {
              activate: isActive
                ? t('global.deactivate').toLowerCase()
                : t('global.activate').toLowerCase(),
              module: caption?.toLowerCase(),
            })}
            toastCaption={t('dashboard.actions.activated', {
              module: caption,
              activated: isActive
                ? t('dashboard.actions.deactivated_caption').toLowerCase()
                : t('dashboard.actions.activated_caption').toLowerCase(),
            })}
          />,
        )

        const dataTestPostfix = isActive ? `option-deactivate-${rowId}` : `option-activate-${rowId}`

        return (
          <ActionMenuItem
            key={'action-activate'}
            dataTestAttributes={getDataTestAttributes(!!testAttributePostfix, dataTestPostfix)}
            onClick={() => {
              setIsActivateOpen(true)
            }}
            label={
              isActive
                ? t('dashboard.actions.title.deactivate')
                : t('dashboard.actions.title.activate')
            }
          />
        )
      }
    }
  }

  const removeRender = () => {
    if (actions.remove) {
      const remove = actions.remove
      const shouldRender = actions.remove.shouldRender
        ? actions.remove.shouldRender(instance)
        : true
      if (shouldRender) {
        actionSideElements.push(
          <Confirmation
            key="remove-modal"
            opened={isRemoveOpen}
            onClose={() => setIsRemoveOpen(false)}
            onConfirm={() => remove.onChange(instance)}
            confirmationText={t('dashboard.actions.remove_confirmation', {
              module: caption?.toLowerCase(),
            })}
            toastCaption={
              actions.remove.showSuccessToast !== false &&
              t('dashboard.actions.removed', { module: caption })
            }
          />,
        )

        const Item = actions.remove.CustomActionMenuItem ?? ActionMenuItem

        return (
          <Item
            key={'action-remove'}
            dataTestAttributes={getDataTestAttributes(
              !!testAttributePostfix,
              `option-remove-${rowId}`,
            )}
            onClick={() => {
              setIsRemoveOpen(true)
            }}
            label={
              actions.remove.CustomActionMenuItem ? undefined : t('dashboard.actions.title.remove')
            }
          />
        )
      }
    }
  }

  const getActionsToRender = () => {
    const actionsToRender: Record<keyof ActionsInterface<T, K>, () => ReactNode> = {
      openLink: openLinkRender,
      custom: customRender,
      settings: settingsRender,
      edit: editRender,
      activate: activateRender,
      remove: removeRender,
    }
    if (actionsOrder) {
      const sortedActionsKeys = (
        Object.keys(actionsToRender) as Array<keyof ActionsInterface<T, K>>
      ).sort((a, b) => {
        const indexA = actionsOrder.indexOf(a)
        const indexB = actionsOrder.indexOf(b)
        return indexA - indexB
      })

      return sortedActionsKeys.reduce((acc, key) => {
        acc[key] = actionsToRender[key]
        return acc
      }, {} as Record<keyof ActionsInterface<T, K>, () => ReactNode>)
    }
    return actionsToRender
  }

  const renderActions = Object.values(getActionsToRender()).map(renderFunction => renderFunction())

  const thereAreActionsToRender = !!renderActions?.flat()?.filter(item => !!item)?.length

  return (
    <div className={twMerge('flex items-center', !thereAreActionsToRender && 'invisible')}>
      <ActionMenu
        isFetching={isFetching}
        dataTestAttributes={getDataTestAttributes(!!testAttributePostfix, `options-${rowId}`)}
        toggleOnHover
        menuItems={renderActions}
      />
      {actionSideElements.map(el => (
        <div key={el.key}>{el}</div>
      ))}
    </div>
  )
}
