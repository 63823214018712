import {
  ACTION_MENU_ITEM_CLASS_NAME,
  ActionMenuItem,
  ActionMenuItemProps,
} from '@systemeio/ui-shared'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'

type ActionMenuLinkItemProps = Omit<
  ActionMenuItemProps,
  'label' | 'onClick' | 'buttonClassName' | 'labelClassName'
> & {
  isNext?: boolean
  label: string | JSX.Element
  href: string
  openInNew?: boolean
  dataTestAttributes?: Record<string, string>
}

export const ActionMenuLinkItemNew = ({
  label,
  href,
  openInNew,
  dataTestAttributes,
  isNext,
  ...props
}: ActionMenuLinkItemProps) => (
  <ActionMenuItem {...props}>
    {({ focus }) => (
      <LinkWithoutPrefetch
        type="button"
        aria-pressed={focus}
        href={href}
        className={ACTION_MENU_ITEM_CLASS_NAME}
        {...dataTestAttributes}
        {...(openInNew ? { target: '_blank', rel: 'noreferrer' } : {})}
      >
        {label}
      </LinkWithoutPrefetch>
    )}
  </ActionMenuItem>
)
