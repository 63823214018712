import React from 'react'
import { ActionsRender } from './components/actions-render'
import { ActionsRenderProps, WithAdditionalType } from './components/actions-render/types'
import { BaseTable, BaseTableProps } from './components/base-table'
import { ACTION_KEY } from './constants'
import { ColumnDefinitionInterface } from './types/column-interface'
import { InstanceForPaginationType } from './types/instance-interface'
import { getRowId } from './utils'

export type TableProps<T, K> = BaseTableProps<T> &
  Partial<Omit<ActionsRenderProps<T, K>, 'instance'>>

export function Table<T extends InstanceForPaginationType<T>, K>({
  testAttributePostfix,
  nestedId,
  paginationIdField,
  actions,
  caption,
  columns,
  actionsOrder,
  ...restProps
}: TableProps<T, K>) {
  {
    const columnsWithActions: ColumnDefinitionInterface<T>[] =
      actions && Object.keys(actions).length > 0
        ? [
            ...columns,
            {
              key: ACTION_KEY as keyof T,
              header: '',
              onRender: instance => (
                <ActionsRender
                  caption={caption}
                  actions={actions}
                  instance={instance as WithAdditionalType<T, K>}
                  rowId={getRowId({
                    row: instance,
                    nestedId,
                    paginationIdField,
                    rowIdParser: restProps.rowIdParser,
                  })}
                  testAttributePostfix={testAttributePostfix}
                  actionsOrder={actionsOrder}
                />
              ),
            },
          ]
        : columns

    return (
      <BaseTable
        nestedId={nestedId}
        paginationIdField={paginationIdField}
        columns={columnsWithActions}
        testAttributePostfix={testAttributePostfix}
        {...restProps}
      />
    )
  }
}
