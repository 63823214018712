import { Modal, ModalSizeEnum } from '@systemeio/ui-shared'
import { Popover } from '@systemeio/ui-shared'
import {
  CustomDomainsStatusEnum,
  CustomDomainsStatusTitleEnum,
} from 'modules/profile/custom-domains-settings/enums/custom-domains-settings-enum'
import { CustomDomainsSettingsInterface } from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import React, { useEffect, useState } from 'react'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { DataWithPaginationInterface } from 'shared/types/pagination-interface'
import { getDomainInfoLink } from 'shared/utils/get-domain-info-link'

interface DnsModalInterface {
  domain: CustomDomainsSettingsInterface
  needToOpenDns?: number
}

interface DnsRecordInterface {
  id: string
  host: string
  value: string
}

const DnsModal = ({ domain, needToOpenDns }: DnsModalInterface) => {
  const { t } = useLocoTranslation()
  const [opened, setOpened] = useState(false)
  useEffect(() => {
    if (needToOpenDns && needToOpenDns === domain.id) {
      setOpened(true)
    }
  }, [needToOpenDns, domain])
  const { user } = useUser()
  const renderTooltip = (): JSX.Element | null => {
    switch (domain.status) {
      case CustomDomainsStatusEnum.aws_caa_failed: {
        return (
          <Popover
            popoverClassName={'w-[300px] md:w-[600px]'}
            label={t('dashboard.settings.custom_domains.status.aws_caa_failed_description')}
          >
            {show => (
              <QuestionMarkIcon
                className={`${
                  show ? 'fill-blue' : 'fill-gray-100'
                } group-focus-visible:fill-blue w-[16px] h-[16px]`}
              />
            )}
          </Popover>
        )
      }
      case CustomDomainsStatusEnum.aws_cname_already_used: {
        return (
          <Popover
            popoverClassName={'w-[300px] md:w-[600px]'}
            label={t('dashboard.settings.custom_domains.status.aws_cname_already_used_description')}
          >
            {show => (
              <QuestionMarkIcon
                className={`${
                  show ? 'fill-blue' : 'fill-gray-100'
                } group-focus-visible:fill-blue w-[16px] h-[16px]`}
              />
            )}
          </Popover>
        )
      }
      case CustomDomainsStatusEnum.deactivated: {
        return (
          <Popover
            popoverClassName={'w-[300px] md:w-[600px]'}
            label={t('dashboard.settings.custom_domains.status.deactivated_description')}
          >
            {show => (
              <QuestionMarkIcon
                className={`${
                  show ? 'fill-blue' : 'fill-gray-100'
                } group-focus-visible:fill-blue w-[16px] h-[16px]`}
              />
            )}
          </Popover>
        )
      }
      default: {
        return null
      }
    }
  }

  const data: DataWithPaginationInterface<DnsRecordInterface> = domain.dnsSettings
    ? {
        items: Object.entries(domain.dnsSettings).map(([host, value]) => ({
          id: host,
          host,
          value,
        })),
        hasMore: false,
      }
    : {
        items: [],
        hasMore: false,
      }

  const columns: ColumnDefinitionInterface<DnsRecordInterface>[] = [
    {
      key: 'first',
      header: '',
      onRender: instance => <span className={'break-all whitespace-normal'}>{instance.host}</span>,
      onRenderSmall: instance => (
        <span className={'break-all whitespace-normal'}>{instance.host}</span>
      ),
      widthPercent: 45,
    },
    {
      key: 'second',
      header: '',
      onRender: () => <span>CNAME</span>,
      onRenderSmall: () => <span>CNAME</span>,
      widthPercent: 10,
    },
    {
      key: 'third',
      header: '',
      onRender: instance => <span className={'break-all whitespace-normal'}>{instance.value}</span>,
      onRenderSmall: instance => (
        <span className={'break-all whitespace-normal'}>{instance.value}</span>
      ),
      widthPercent: 45,
    },
  ]

  return (
    <div className={'truncate flex gap-3 flex-col sm:flex-row'}>
      <div className={'flex gap-1 items-center'}>
        <span className={'truncate'}>{t(CustomDomainsStatusTitleEnum[domain.status])}</span>
        {renderTooltip()}
      </div>
      {domain.type === 'aws' && domain.status !== CustomDomainsStatusEnum.aws_remove_in_progress && (
        <button className={'truncate primary-link'} onClick={() => setOpened(true)}>
          {t('dashboard.settings.custom_domains.show_instructions')}
        </button>
      )}
      <Modal
        title={t('dashboard.settings.custom_domains.dns_configuration')}
        size={ModalSizeEnum.medium}
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <div className={'flex flex-col gap-5 items-center'}>
          <Table data={data} columns={columns} noHeader />
          <a
            className={'primary-link'}
            href={getDomainInfoLink(user?.dashboardLocale)}
            target={'_blank'}
            rel={'noreferrer'}
          >
            {t('dashboard.settings.custom_domains.add_domain_description')}
          </a>
        </div>
      </Modal>
    </div>
  )
}

export default DnsModal
