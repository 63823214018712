import { BaseTableProps } from './components/base-table'
import { ColumnDefinitionInterface } from './types/column-interface'
import {
  InstanceForPaginationType,
  InstanceWithIdType,
  InstanceWithNestedIdType,
} from './types/instance-interface'

export const calculateTableTotalWidth = <T>(columns: ColumnDefinitionInterface<T>[]) =>
  columns
    .filter(c => c.widthPercent !== undefined)
    .reduce((prev, cur) => prev + (cur.widthPercent || 25), 0)

export function getRowId<T extends InstanceForPaginationType<T>>({
  row,
  nestedId,
  paginationIdField,
  rowIdParser,
}: {
  row: T
  nestedId: BaseTableProps<T>['nestedId']
  paginationIdField: BaseTableProps<T>['paginationIdField']
  rowIdParser?: (row: T) => string | number
}) {
  if (rowIdParser) return rowIdParser(row)

  if (paginationIdField) {
    return row[paginationIdField]
  } else {
    if (nestedId) return (row[nestedId] as InstanceWithNestedIdType<any>).id
    else return (row as InstanceWithIdType).id
  }
}
