import React, { RefObject, useEffect, useState } from 'react'
import { LightButton } from 'shared/components/button'
import { BaseTableProps } from 'shared/components/table/components/base-table'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { LimitType, OrderEnum } from '../../../types/pagination-interface'
import { InstanceForPaginationType } from '../types/instance-interface'
import { getRowId } from '../utils'

export interface TableLimitPaginationProps<T>
  extends Pick<BaseTableProps<T>, 'data' | 'nestedId' | 'paginationIdField' | 'rowIdParser'>,
    Required<Pick<BaseTableProps<T>, 'limiter' | 'paginator'>> {
  tableRef?: RefObject<HTMLDivElement>
}

const limitArray: LimitType[] = [10, 25, 50]

const TableLimitPagination = <T extends InstanceForPaginationType<T>>({
  data,
  limiter,
  paginator,
  nestedId,
  paginationIdField,
  rowIdParser,
  tableRef,
}: TableLimitPaginationProps<T>) => {
  const { t } = useLocoTranslation()
  const { pagination, setPagination } = paginator
  const { limit, setLimit } = limiter

  const onPaginationClick = () => {
    if (tableRef?.current) {
      tableRef?.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const [isPaginationShown, setIsPaginationShown] = useState(true)

  const [isFirst, setIsFirst] = useState(true)

  const [isLast, setIsLast] = useState(false)

  useEffect(() => {
    if (!isFirst && data?.items.length === 0) {
      setPagination({ order: OrderEnum.next })
    }
  }, [data?.items.length, isFirst, setPagination])

  useEffect(() => {
    if (data) {
      setIsFirst(true)
      setIsLast(false)

      switch (pagination.order) {
        case 'next': {
          if (pagination.startFromId) {
            setIsFirst(false)
          }
          if (!data.hasMore) {
            setIsLast(true)
          }
          break
        }
        case 'prev': {
          if (data.hasMore) {
            setIsFirst(false)
          }
        }
      }
    }
  }, [data, pagination.order, pagination.startFromId])

  useEffect(() => {
    if (data) {
      setIsPaginationShown(true)

      if (!isFirst || limiter.limit !== 10) return

      if (pagination.order === 'next' && !data.hasMore) {
        setIsPaginationShown(false)
      }
      if (data.items.length === 0) {
        setIsPaginationShown(false)
      }
    } else {
      setIsPaginationShown(false)
    }
  }, [data, isFirst, limiter.limit, pagination.order, pagination.startFromId])

  return isPaginationShown ? (
    <div className={'items-center gap-5 sm:gap-10 flex'} data-test="table-pagination">
      <div className={`flex gap-2`}>
        {limitArray.map((el, index) => (
          <button
            key={el}
            className={`flex justify-center items-center rounded-full w-9 h-9 text-sm font-bold cursor-pointer outline-none focus-visible:ring-2 ring-blue ring-offset-1 ${
              limit === el ? 'bg-white text-blue' : 'bg-transparent text-darkblue/50 '
            }`}
            onClick={() => {
              setLimit(el)
              setPagination({
                startFromId: undefined,
                order: OrderEnum.next,
              })
              onPaginationClick()
            }}
            data-test={`table-pagination-limit-button-${index}`}
          >
            {el}
          </button>
        ))}
      </div>
      <div className={`flex gap-3`}>
        <LightButton
          disabled={isFirst}
          onClick={() => {
            if (!data) return

            const startFromId = getRowId<T>({
              row: data?.items[0],
              nestedId,
              paginationIdField,
              rowIdParser,
            })

            setPagination({
              startFromId,
              order: OrderEnum.prev,
            })
            onPaginationClick()
          }}
          data-test="table-pagination-previous-button"
        >
          {t('global.previous')}
        </LightButton>
        <LightButton
          disabled={isLast}
          onClick={() => {
            if (!data) return

            const startFromId = getRowId<T>({
              row: data?.items[data.items.length - 1],
              nestedId,
              paginationIdField,
              rowIdParser,
            })

            setPagination({
              startFromId,
              order: OrderEnum.next,
            })
            onPaginationClick()
          }}
          data-test="table-pagination-next-button"
        >
          {t('global.next')}
        </LightButton>
      </div>
    </div>
  ) : null
}

export default TableLimitPagination
