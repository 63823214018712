import { toast } from '@systemeio/ui-shared'
import { Tooltip } from '@systemeio/ui-shared'
import SettingsLayout from 'modules/profile/components/settings-layout'
import { settingsMenuLocoKeys } from 'modules/profile/constants/settings-menu-loco-keys'
import {
  CUSTOM_DOMAINS_SETTINGS_API,
  editSubDomain,
  markAsDefault,
  removeLoginPage,
  removeNotFoundPage,
} from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import CreateDomainModal from 'modules/profile/custom-domains-settings/components/create-domain-modal'
import DnsModal from 'modules/profile/custom-domains-settings/components/dns-modal'
import DomainSettingsModal from 'modules/profile/custom-domains-settings/components/domain-settings-modal'
import FormEditSubdomainInput from 'modules/profile/custom-domains-settings/components/form-edit-subdomain-input'
import RemoveDomainModal from 'modules/profile/custom-domains-settings/components/remove-domain-modal'
import { CustomDomainsSettingsLoco } from 'modules/profile/custom-domains-settings/constants/custom-domains-settings-loco'
import { CustomDomainsStatusEnum } from 'modules/profile/custom-domains-settings/enums/custom-domains-settings-enum'
import { useCustomDomainsSettings } from 'modules/profile/custom-domains-settings/hooks/use-custom-domains-settings'
import {
  CustomDomainsSettingId,
  CustomDomainsSettingsInterface,
  CustomDomainsSubdomainEditInterface,
} from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import { getDomainUrl } from 'modules/profile/custom-domains-settings/utils/get-domain-url'
import { NextPage } from 'next'
import process from 'process'
import React, { useEffect, useState } from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import EditModal from 'shared/components/edit-modal'
import ListingLayout from 'shared/components/listing-layout'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useUpdateData } from 'shared/hooks/use-update-data'
import useWindowSize from 'shared/hooks/use-window-size'
import CheckIcon from 'shared/icons/check-icon'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'

const CustomDomainsSettings: NextPage = () => {
  const { t } = useLocoTranslation()
  const { setPagination, pagination, setLimit, limit } = usePagination()
  const updateData = useUpdateData({
    setPagination,
    api: CUSTOM_DOMAINS_SETTINGS_API,
  })
  const [needToOpenDns, sdtNeedToOpenDns] = useState<number | undefined>(undefined)

  const { width } = useWindowSize()

  useEffect(() => {
    sdtNeedToOpenDns(undefined)
  }, [width])

  const { customDomains, mutate, counter } = useCustomDomainsSettings({ pagination, limit })

  const [subdomainError, setSubdomainError] = useState('')

  const createDomainWithUpdate = async (domain: CustomDomainsSettingsInterface) => {
    await updateData(true, customDomains)
    sdtNeedToOpenDns(domain.id)
  }

  const removeDomainWithUpdate = async () => {
    sdtNeedToOpenDns(undefined)
    await updateData(false, customDomains)
  }

  const onMarkAsDefault = async (domain: CustomDomainsSettingsInterface) => {
    try {
      sdtNeedToOpenDns(undefined)
      await markAsDefault(domain.id)
      await updateData(false, undefined, {
        mutationData: customDomains,
        onMutate: async () => {
          await mutate(data => {
            if (data) {
              return {
                items: data.items.map(el => {
                  if (el.id === domain.id) {
                    el.defaultDomain = true
                  } else if (el.defaultDomain) {
                    el.defaultDomain = false
                  }
                  return { ...el }
                }),
                hasMore: data.hasMore,
              }
            }
          }, false)
        },
      })
    } catch (e) {
      throw e
    }
  }
  const customDomainsColumns: ColumnDefinitionInterface<CustomDomainsSettingsInterface>[] = [
    {
      key: 'domainName',
      header: t('dashboard.settings.custom_domains.domain_name'),
      widthPercent: 40,
      onRender: customDomain => {
        return (
          <div className={'flex flex-col gap-1 overflow-hidden'}>
            <div className={'flex gap-1.5 items-center'}>
              {customDomain.status === CustomDomainsStatusEnum.active ? (
                <a
                  className={'primary-link w-full h-fit truncate'}
                  href={`${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${customDomain.domainName}`}
                  rel={'noreferrer'}
                  target={'_blank'}
                >
                  {customDomain.domainName}
                </a>
              ) : (
                <span className={'w-full h-fit truncate'}>{customDomain.domainName}</span>
              )}
              {customDomain.defaultDomain && (
                <Tooltip
                  mode={'hover'}
                  label={t('dashboard.settings.custom_domains.default_domain')}
                >
                  <CheckIcon className={'stroke-green'} />
                </Tooltip>
              )}
            </div>
          </div>
        )
      },
    },
    {
      key: 'status',
      header: t('global.status'),
      widthPercent: 40,
      onRender: customDomain => (
        <DnsModal needToOpenDns={width >= 1024 ? needToOpenDns : undefined} domain={customDomain} />
      ),
      onRenderSmall: customDomain => (
        <DnsModal needToOpenDns={width < 1024 ? needToOpenDns : undefined} domain={customDomain} />
      ),
    },
  ]

  const onRemoveLoginPage = async (domain: CustomDomainsSettingsInterface) => {
    try {
      sdtNeedToOpenDns(undefined)
      await removeLoginPage(domain.id)
      await updateData(false, undefined, {
        mutationData: customDomains,
        onMutate: async () => {
          await mutate(data => {
            if (data) {
              return {
                hasMore: data.hasMore,
                items: data.items.map(el => {
                  if (el.id === domain.id) {
                    el.loginPage = null
                  }
                  return { ...el }
                }),
              }
            }
          }, false)
        },
      })
    } catch (e) {
      throw e
    }
  }

  const onRemoveNotFoundPage = async (domain: CustomDomainsSettingsInterface) => {
    try {
      sdtNeedToOpenDns(undefined)
      await removeNotFoundPage(domain.id)
      await updateData(false, undefined, {
        mutationData: customDomains,
        onMutate: async () => {
          await mutate(data => {
            if (data) {
              return {
                hasMore: data.hasMore,
                items: data.items.map(el => {
                  if (el.id === domain.id) {
                    el.notFoundPage = null
                  }
                  return { ...el }
                }),
              }
            }
          }, false)
        },
      })
    } catch (e) {
      throw e
    }
  }

  const onEditSubdomain = async (
    id: CustomDomainsSettingId,
    data: Pick<CustomDomainsSettingsInterface, 'domainName'>,
  ) => {
    try {
      setSubdomainError('')
      sdtNeedToOpenDns(undefined)
      const newData: CustomDomainsSubdomainEditInterface = {
        name: data.domainName,
      }
      await editSubDomain(id, newData)
      await mutate(domains => {
        if (domains) {
          return {
            hasMore: domains.hasMore,
            items: domains.items.map(el => {
              if (el.id === id) {
                el.domainName = `${data.domainName}.${process.env.NEXT_PUBLIC_SIO_HOST_NAME}`
              }
              return { ...el }
            }),
          }
        }
      }, false)
      toast.success(t('global.changes_saved'))
    } catch (e) {
      if (e instanceof BadRequest && e.errors.fields) {
        setSubdomainError(e.errors.fields.name?.join(''))
      }
      throw e
    }
  }

  const editDomainWithUpdate = async (newData: CustomDomainsSettingsInterface) => {
    try {
      sdtNeedToOpenDns(undefined)
      await updateData(false, undefined, {
        mutationData: customDomains,
        onMutate: async () => {
          await mutate(data => {
            if (data) {
              return {
                items: data.items.map(item => {
                  if (item.id === newData.id) item = { ...item, ...newData }
                  return item
                }),
                hasMore: data.hasMore,
              }
            }
          }, false)
        },
      })
    } catch (e) {
      throw e
    }
  }

  return (
    <SettingsLayout
      needSave={false}
      titles={[{ caption: t(settingsMenuLocoKeys.custom_domains), isStatic: true }]}
      customHeader={<CreateDomainModal createDomainWithUpdate={createDomainWithUpdate} />}
    >
      <div className={'w-full h-fit bg-white rounded-lg p-5'}>
        <ListingLayout
          data={customDomains}
          title={t(settingsMenuLocoKeys.custom_domains)}
          titleClassName={'font-semibold'}
          type={'compact'}
          counter={counter}
        >
          <Table
            actions={{
              openLink: [
                {
                  name: 'edit-homepage',
                  shouldRender: customDomain => !!customDomain.homePageEditUrl,
                  caption: t('dashboard.settings.custom_domains.edit_homepage'),
                  setHref: customDomain => getDomainUrl(customDomain),
                  openInNew: false,
                },
              ],
              custom: [
                {
                  shouldRender: domain => domain.type === 'sio_subdomain',
                  name: 'edit-subdomain-name',
                  caption: t('dashboard.settings.custom_domains.edit_subdomain'),
                  onActionRender: (opened, onClose, instance, key) => (
                    <EditModal
                      fullCaption={t('dashboard.settings.custom_domains.edit_sio_subdomain')}
                      key={key}
                      opened={opened}
                      onClose={onClose}
                      instance={instance}
                      editable={[
                        {
                          field: 'domainName',
                          onRender: (value, onChange) => (
                            <FormEditSubdomainInput
                              key={'subdomain-edit'}
                              error={subdomainError}
                              value={value}
                              onChangeValue={onChange}
                            />
                          ),
                        },
                      ]}
                      onEdit={data => onEditSubdomain(instance.id, data)}
                    />
                  ),
                },
                {
                  shouldRender: customDomain =>
                    !customDomain.defaultDomain &&
                    customDomain.status === CustomDomainsStatusEnum.active,
                  name: 'set-default',
                  caption: t('dashboard.settings.custom_domains.make_default_domain'),
                  onActionRender: (opened, onClose, instance, key) => (
                    <ConfirmationModal
                      opened={opened}
                      onClose={onClose}
                      key={key}
                      onConfirm={() => onMarkAsDefault(instance)}
                      confirmationText={t('dashboard.settings.custom_domains.make_default_domain')}
                    />
                  ),
                },
                {
                  name: 'domain-settings',
                  caption: t('dashboard.actions.title.settings'),
                  onActionRender: (opened, onClose, instance, key) => (
                    <DomainSettingsModal
                      editDomainWithUpdate={editDomainWithUpdate}
                      domain={instance}
                      opened={opened}
                      onClose={onClose}
                      key={key}
                      onRemoveLoginPage={onRemoveLoginPage}
                      onRemoveNotFoundPage={onRemoveNotFoundPage}
                    />
                  ),
                },
                {
                  name: 'domain-remove',
                  caption: t('dashboard.actions.title.remove'),
                  shouldRender: domain =>
                    domain.type === 'aws' &&
                    domain.status !== CustomDomainsStatusEnum.aws_remove_in_progress,
                  onActionRender: (opened, onClose, instance, key) => (
                    <RemoveDomainModal
                      opened={opened}
                      onClose={onClose}
                      domain={instance}
                      removeDomainWithUpdate={removeDomainWithUpdate}
                      key={key}
                    />
                  ),
                },
              ],
            }}
            data={customDomains}
            columns={customDomainsColumns}
            limiter={{ limit, setLimit }}
            paginator={{ pagination, setPagination }}
            emptyListWithTitles
          />
        </ListingLayout>
      </div>
    </SettingsLayout>
  )
}

export const getStaticProps = getBaseServerSideProps(CustomDomainsSettingsLoco, 'global.settings')

export default CustomDomainsSettings
