import {
  affiliateSettingsMenuHref,
  affiliateStudentSettingsMenuHref,
  customerSettingsMenuHref,
  workspaceAdminSettingsMenuHref,
  workspaceAssistantSettingsMenuHref,
} from 'modules/profile/constants/settings-menu-href'
import { settingsMenuLocoKeys } from 'modules/profile/constants/settings-menu-loco-keys'
import { SettingsMenuEnum } from 'modules/profile/enums/settings-menu-enum'
import { SettingsMenuInterface } from 'modules/profile/types/settings-interface'
import { getMenuItemDataTestId } from 'modules/profile/utils/get-menu-item-data-test-id'
import { UserStatusEnum } from 'shared/enums/user-roles-enum'
import useUser from 'shared/hooks/use-user'
import { isMatchingMenuItemCurried } from '../utils/menu-path-validators'
import { useSettingsMenuItemsFilter } from './use-settings-menu-items-filter'

export const useSettingsMenuItems = (): SettingsMenuInterface => {
  const { isWorkspaceOwner, isWsAdmin, isWsAssistant, userStatus } = useUser()

  const { filterMenuItems } = useSettingsMenuItemsFilter()

  const createItemsArray = <
    T extends Partial<Record<SettingsMenuEnum, string>> = Partial<Record<SettingsMenuEnum, string>>,
  >(
    settingsMenuHref: T,
  ) => {
    const items = Object.keys(settingsMenuHref) as SettingsMenuEnum[]

    return filterMenuItems(items).map(el => {
      const key = el as keyof typeof SettingsMenuEnum
      return {
        href: settingsMenuHref[key] as keyof T,
        name: settingsMenuLocoKeys[key],
        validate: isMatchingMenuItemCurried(settingsMenuHref[key] as string, SettingsMenuEnum[key]),
        dataTestId: getMenuItemDataTestId(el),
      }
    })
  }

  if (!isWorkspaceOwner) {
    if (isWsAdmin) {
      return createItemsArray(workspaceAdminSettingsMenuHref)
    } else if (isWsAssistant) {
      return createItemsArray(workspaceAssistantSettingsMenuHref)
    }
  }
  switch (userStatus) {
    case UserStatusEnum.customer: {
      return createItemsArray(customerSettingsMenuHref)
    }
    case UserStatusEnum.affiliate: {
      return createItemsArray(affiliateSettingsMenuHref)
    }

    case UserStatusEnum.affiliate_student: {
      return createItemsArray(affiliateStudentSettingsMenuHref)
    }
    default:
      return []
  }
}
