import { twJoin } from 'tailwind-merge'
import ArrowDownSmoothIcon from '../../../icons/arrow-down-smooth-icon'
import { SortDirectionEnum } from '../enums'

interface TableSortElementProps {
  sortOrder: SortDirectionEnum | null
  onSortOrderChange?: () => void
  className?: string
}

export function TableSortElement({
  sortOrder,
  onSortOrderChange,
  className,
}: TableSortElementProps) {
  const sortIcon = (() => {
    switch (sortOrder) {
      case SortDirectionEnum.Asc:
        return <ArrowDownSmoothIcon width={10} height={6} className="mb-1" />
      case SortDirectionEnum.Desc:
        return <ArrowDownSmoothIcon width={10} height={6} className="rotate-180" />
      case null:
        return (
          <div className="flex flex-col gap-[1px]">
            <ArrowDownSmoothIcon width={10} height={6} className="fill-gray" />
            <ArrowDownSmoothIcon width={10} height={6} className="rotate-180 fill-gray" />
          </div>
        )
    }
  })()

  return (
    <button
      type="button"
      onClick={e => {
        e.stopPropagation()
        onSortOrderChange?.()
      }}
      className={twJoin('flex items-center', className)}
    >
      <span>{sortIcon}</span>
    </button>
  )
}
