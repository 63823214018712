import { LocaleEnum, SharedLocaleProvider } from '@systemeio/ui-shared'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import React from 'react'
import Header from 'shared/components/header'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { useAccountRestrictedNotification } from 'shared/notifications/hooks/use-account-restricted-notification'
import { useEmailStatusNotification } from 'shared/notifications/hooks/use-email-status-notification'
import { usePaymentNotification } from 'shared/notifications/hooks/use-payment-notification'
import LiveChat from '../../../live-chat'

const disabledPageRoute = '/disabled'

function DashboardLayout({ children, browserTabTitle }: React.PropsWithChildren<any>) {
  useAccountRestrictedNotification()
  useEmailStatusNotification()
  usePaymentNotification()

  const { t } = useLocoTranslation()

  const { pathname } = useRouter()

  const { isDisabled, user } = useUser()

  if (isDisabled && pathname !== disabledPageRoute) {
    Router.replace(disabledPageRoute)
  }

  return (
    <>
      {process.env.NEXT_PUBLIC_APP_ENV === 'production' && <LiveChat />}
      <Head>
        <title>{t(browserTabTitle)}</title>
      </Head>
      <div>
        <Header />
        <div className="px-4 sm:px-6 md:px-14">
          <SharedLocaleProvider locale={user?.dashboardLocale ?? LocaleEnum.ENGLISH}>
            <main className="py-5">{children}</main>
          </SharedLocaleProvider>
        </div>
      </div>
    </>
  )
}

export default DashboardLayout
