import { Float } from '@headlessui-float/react'
import { Popover } from '@headlessui/react'
import { useRouter } from 'next/router'
import React from 'react'
import { TopMenuSubItem } from 'shared/components/header/components/desktop-menu/components/desktop-menu-item'
import { ABSOLUTE_PATH_PREFIX } from 'shared/constants/base-path-url'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useShowChangePasswordPage } from 'shared/hooks/use-show-change-password-page'
import useUser from 'shared/hooks/use-user'
import ChevronDownIcon from 'shared/icons/chevron-down-icon'

function Avatar() {
  const { dashboardUser, isWorkspaceOwner, isLoading, isDisabled } = useUser()
  const { showChangePasswordPage } = useShowChangePasswordPage()
  const { t } = useLocoTranslation()
  const { pathname } = useRouter()

  const isActive = pathname.includes('/profile')

  const { show, delayClose, delayOpen } = useFloatHover()

  if (isLoading) {
    return <div className="h-10 w-10 animate-pulse rounded-full bg-gray" />
  }
  if (!dashboardUser) return null
  return (
    <Popover className={'h-full'}>
      <Float
        show={show}
        placement="bottom"
        offset={-5}
        arrow={5}
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={'h-full flex items-center shrink-0'}
      >
        <Popover.Button
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          className={`h-full flex items-center gap-2 focus:outline-none border-b-4 focus-visible:border-blue ${
            isActive ? 'border-blue' : 'border-transparent '
          }`}
        >
          <img
            className="h-10 w-10 rounded-full object-cover"
            src={dashboardUser.avatarUrl}
            alt={`${dashboardUser.firstName} ${dashboardUser.lastName}`}
            data-test-id={'top-menu-avatar'}
          />
          <ChevronDownIcon />
        </Popover.Button>

        <Popover.Panel
          onMouseEnter={delayOpen}
          onMouseLeave={delayClose}
          className="bg-white rounded-lg shadow-lg z-50 max-w-[240px]"
        >
          <Float.Arrow className={`absolute bg-white w-4 h-4 rotate-45`} />
          <ul className={'flex flex-col text-gray-300 text-sm py-4'}>
            <>
              {!isDisabled && (
                <>
                  <li>
                    {isWorkspaceOwner ? (
                      <TopMenuSubItem
                        dataTestId={'settings-link'}
                        name={t('global.settings')}
                        href={'/profile/user-settings'}
                      />
                    ) : (
                      <TopMenuSubItem
                        name={t('global.settings')}
                        href={'/profile/account-settings'}
                      />
                    )}
                  </li>
                  {showChangePasswordPage && (
                    <li>
                      <TopMenuSubItem
                        name={t('header.user.profile.menu.change_password')}
                        href={'/profile/change-password'}
                      />
                    </li>
                  )}
                  <hr className="text-[#ebedf0] px-4 my-1 mx-5" />
                </>
              )}
              {dashboardUser?.impersonator && (
                <li>
                  {/*<ExitImpersonateButton>*/}
                  {/*  <p className={AVATAR_MENU_CLASS}>{t('header.exit_impersonation')}</p>*/}
                  {/*</ExitImpersonateButton>*/}
                  <TopMenuSubItem
                    name={t('header.exit_impersonation')}
                    href={`${ABSOLUTE_PATH_PREFIX}/dashboard/admin/management/user/list?pretend_this_user=_exit`}
                  />
                </li>
              )}
            </>
            <li>
              <TopMenuSubItem
                name={t('header.user.profile.menu.logout')}
                href={`${ABSOLUTE_PATH_PREFIX}/dashboard/logout`}
              />
            </li>
          </ul>
        </Popover.Panel>
      </Float>
    </Popover>
  )
}

export default Avatar
