import { Tooltip } from '@systemeio/ui-shared'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { ACTION_KEY } from '../constants'
import { BaseTableProps } from './base-table'
import { TableSortElement } from './table-sort-element'

interface TableHeaderProps<T> extends Pick<BaseTableProps<T>, 'columns'> {
  totalWidth: number
}

export function TableHeader<T>({ columns, totalWidth }: TableHeaderProps<T>) {
  const headers = columns.map((column, index) => {
    const widthPercent = column.widthPercent || 25
    return (
      <div
        key={`headCell-${index}`}
        className={twMerge(
          'text-start text-darkblue px-6 py-4 hidden lg:flex lg:items-center font-bold font-inter text-[17px] truncate',
          column.sortOrder !== undefined && 'cursor-pointer',
          column.className,
        )}
        style={{
          width: `${column.key === ACTION_KEY && `90px`}`,
          flex: `${column.key !== ACTION_KEY && `${(widthPercent / totalWidth) * 100}`}`,
        }}
        onClick={column.onSortOrderChange}
      >
        {typeof column.header === 'string' ? (
          <div className="w-full">
            <Tooltip mode="overflow" label={column.header}>
              {column.header}
            </Tooltip>
          </div>
        ) : (
          column.header
        )}

        {column.sortOrder !== undefined && (
          <TableSortElement
            sortOrder={column.sortOrder}
            onSortOrderChange={column.onSortOrderChange}
            className="ml-2"
          />
        )}
      </div>
    )
  })

  return <div className="flex items-center bg-blue-100">{headers}</div>
}
