import { Modal, toast } from '@systemeio/ui-shared'
import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton } from 'shared/components/button'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { BadRequest } from '../../errors/bad-request'
import { InternalError } from '../../errors/internal-error'
import { FieldErrorAndDescription } from '../form/field-error-and-description'

export interface ConfirmationProps {
  opened: boolean
  onClose: () => void
  onConfirm: () => Promise<void> | void
  onDecline?: () => Promise<void> | void
  title?: string
  confirmationText?: string | JSX.Element
  toastCaption?: string | JSX.Element
  className?: string
  confirmButtonCaption?: string
  cancelButtonCaption?: string
  buttonClassName?: string
  buttonTextClassName?: string
  confirmationContent?: JSX.Element
  afterLeave?: () => void
}

const defaultTitle = 'confirmation_modal.default_title'

function Confirmation({
  opened,
  onClose,
  onConfirm,
  onDecline,
  title,
  confirmationText = '',
  toastCaption,
  className,
  cancelButtonCaption,
  confirmButtonCaption,
  buttonClassName,
  buttonTextClassName,
  confirmationContent,
  afterLeave,
}: ConfirmationProps) {
  const { t } = useLocoTranslation()
  const [error, setError] = useState('')
  const [isFetching, setIsFetching] = useState(false)

  const [isConfirmed, setIsConfirmed] = useState(false)

  const handleConfirm = async () => {
    try {
      setIsFetching(true)
      await onConfirm()
      setIsFetching(false)
      setIsConfirmed(true)
      onClose()
      if (toastCaption) {
        toast.success(toastCaption)
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof UnprocessableContentError) {
        // do nothing while we're migrating 400 errors
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const onModalClose = () => {
    setError('')
    setIsConfirmed(false)
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          if (!isConfirmed && onDecline) {
            onDecline()
          }
          onClose()
        }}
        title={title ? title : t(defaultTitle)}
        afterLeave={() => {
          afterLeave && afterLeave()
          onModalClose()
        }}
        isFetching={isFetching}
        isCentered={true}
        className={className}
      >
        <form className="flex flex-col gap-6 lg:gap-10">
          {confirmationText && (
            <div className="flex justify-center">
              <p className="text-darkblue text-base">{confirmationText}</p>
            </div>
          )}
          {confirmationContent}
          <div className="flex justify-center gap-4">
            <PrimaryButton
              isFetching={isFetching}
              className={`w-full ${buttonClassName || ''}`}
              buttonTextClassName={buttonTextClassName}
              width="large"
              onClick={async e => {
                e.preventDefault()
                await handleConfirm()
              }}
              type="submit"
            >
              {confirmButtonCaption || t('confirmation_modal.confirm')}
            </PrimaryButton>
            <SecondaryButton
              className={`w-full ${buttonClassName || ''}`}
              width="large"
              buttonTextClassName={buttonTextClassName}
              onClick={e => {
                e.preventDefault()
                onDecline && onDecline()
                onClose()
              }}
              disabled={isFetching}
            >
              {cancelButtonCaption || t('confirmation_modal.cancel')}
            </SecondaryButton>
          </div>
          <FieldErrorAndDescription error={error} errorClassName={'text-center'} />
        </form>
      </Modal>
    </>
  )
}

export default Confirmation
